import React from "react"
import { Styled } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

export default function BioContent() {
  const { site: {siteMetadata: {author , siteUrl}}} = useStaticQuery(
        graphql`query { site { siteMetadata { author, siteUrl } } }`
  );

  return <>Words by <Styled.a href="/">{author}</Styled.a>.</>

}